import React from "react"
import ReactDOM from "react-dom/client"
import "./index.scss"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import ErrorPage from "./routes/error-page"
import LilyStory from "./routes/lily-story"
import { Bingo } from "./routes/bingo"
import { RouteObject } from "react-router"

const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'stories',
        element: <div />
      },
      {
        path: 'story/lily',
        element: <LilyStory />
      },
      {
        path: 'bingo',
        children: [
          {
            path: '',
            element: <Bingo editable={false} />,
          },
          {
            path: ':bingoId',
            loader: ({params}): null => {
              console.log(params)

              return null
            },
            element: <Bingo editable={true} />,
          }
        ]
      }
    ]
  },
];
const router = createBrowserRouter(routes)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
