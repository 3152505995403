import React from "react"
import vae from "../images/vae/vae.png"
import mourne from "../images/mourne/mourne.png"
import erahn from "../images/erahn/erahn.png"
import CharacterCard from "./character-card"
import {Link} from "react-router-dom"
import "./Top.scss"

function Top(): React.ReactElement {
  return (
    <div className="top p-2 m-2 border-5 rounded-5 bg-opacity-75 bg-dark d-flex">
      <Link to={'/story/lily'}>
        <CharacterCard image={vae} name="Vae Kalphazem" />
      </Link>
      <Link to={'/bingo/123'}>
        <CharacterCard image={mourne} name="Mourne Kotei" />
      </Link>
      <Link to={'/bingo'}>
        <CharacterCard image={erahn} name="E'rahn Tia" />
      </Link>
    </div>
  )
}

export default Top
