import React from "react";

function LilyStory(): React.ReactElement {
  return (
    <>
      <p>
        <div>“Is this-”</div>
        <div>“Yes.”</div>
      </p>

      <p>E’Rahn chuckled quietly and reached out to touch the lilac flowers that were placed delicately under Vae’s left ear, secured by a small silver pin in the darker parts of her hair.</p>

      <p>
        <div>“Are you quite done?”</div>
        <div>“No~”</div>
        <div>“E’Rahn.”</div>
        <div>“It’s just so unusual…Makes you look lively and…tender”</div>
        <div>“Tender.”</div>
        <div>“Soft and cute~”</div>
        <div>“I will kill you.”</div>
      </p>

      <p>
        “Aww, but that would mess up the flowers.” which felt surprisingly firm, they were enforced by means of
        alchemy to stay looking fresh, no doubt. E’Rahn himself had to make a few of them when he studied the craft of
        Alchemy in Ul’dah and although it was something you learned early on, there was a certain art to perfecting
        them.
      </p>

      <p>“They are very secure, don’t you worry.” a slight cheeky smile stole it’s way onto Vae’s face and she turned away momentarily to take back her spear from the Blacksmith that sharpened it.</p>

      <p>
        <div>“How come you’re wearing flowers all of the sudden? Do we have something to celebrate?”</div>
        <div>“I just felt like it.”</div>
        <div>“Well, they do look adorable.”</div>
        <div>“Stop.”</div>
        <div>“I’m serious, you look very sweet.” E’Rahn felt himself smiling winningly upon seeing a slight blush appear on his lover's cheeks.</div>
      </p>

      <p>Vae turned away while securing her spear on her back.</p>

      <p>
        <div>“Charlotte’s waiting for me, we’re on patrol duty.”</div>
        <div>“Absolutely. You can tell her she did a very good job with those flowers”</div>
        <div>“She knows”</div>
        <div>“Of course she does. Just as she knew you would wear them.”</div>
        <div>“You’re annoying.” she chuckled.</div>
      </p>

      <p>
        “Oh pierce my heart, warrior of light…” E’Rahn grabbed his chest and let himself fall back a few steps behind her, pure agony painted on his features.
      </p>

      <p>
        Her laughter was music in his ears and his ears wiggled softly, leading to a bright smile.
      </p>

      <p>
        “I’ll see you both later at dinner then.” he said, watching her turn around again and heading further down the bridge that leads out of Chrystarium.
      </p>

      <p>“I’ll ask Charlotte if she can make you some flowers too~”</p>
    </>
  )
}

export default LilyStory
