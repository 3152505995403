import "./bingo.scss"
import { Outlet } from "react-router-dom"

export type PropTypes = {
  editable: boolean
}

export function Bingo({ editable = false }: PropTypes): JSX.Element {
  console.log(editable)
  const bingoCells: JSX.Element[] = []
  for (let i = 0; i < 25; i++) {
    bingoCells.push(
      <div key={i} className="border-2 rounded border-light bg-opacity-50 bg-gradient w-100 h-100 d-flex align-items-center justify-content-center">
        <div contentEditable={editable}>test</div>
      </div>
    )
  }

  return (
    <>
      <Outlet />
      <div className="d-flex align-items-center justify-content-center">
        <div className="bingo-container">
          { bingoCells }
        </div>
      </div>
    </>
  )
}
