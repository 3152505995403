import React from "react"
import {Outlet} from "react-router-dom"

function Main(): React.ReactElement {
  return (
    <main className="m-2 p-4 flex-grow-1 rounded-5 bg-opacity-75 bg-dark text-bg-dark">
      <Outlet />
    </main>
  )
}

export default Main
