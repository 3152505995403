import React from "react"
import {Link} from "react-router-dom"
import icon from "../icon.png"
import "./Aside.scss"

export function Aside(): React.ReactElement {
  return (
    <aside className="d-flex flex-column m-2 rounded-5 bg-opacity-75 bg-dark">
      <div className="flex-fill" />
      <div className="border border-primary border-5 align-self-end">
        <Link to={'/story/lily'}><img src={icon} alt="icons" /></Link>
        <Link to={'/story/lily'}><img src={icon} alt="icons" /></Link>
        <Link to={'/story/lily'}><img src={icon} alt="icons" /></Link>
        <Link to={'/'}><img src={icon} alt="icons" /></Link>
      </div>
    </aside>
  )
}

export default Aside
