import { useRouteError } from 'react-router-dom'

export default function ErrorPage(): React.ReactElement {
  const error = useRouteError() as { statusText?: string, message?: string }
  console.error(error)

  return (
    <div>{ error.statusText || error.message }</div>
  )
}
