import React from "react"
import "./App.scss"
import Top from "./components/Top"
import { Aside } from "./components/Aside"
import Main from "./components/Main"

function App(): React.ReactElement {
  return (
    <>
      <Top></Top>
      <div className="main d-flex">
        <Main />
        <Aside />
      </div>
    </>
  )
}

export default App
