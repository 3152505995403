import React from "react"
import "./character-card.scss"

type CharacterCardProps = { image: string, name: string };

function CharacterCard({ image, name }: CharacterCardProps): React.ReactElement {
  return <img src={image} className="character-image" alt={name} />
}

export default CharacterCard
